body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600&display=swap');
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  font-size: 10px !important;
}
.h1center {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
h1 {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
h2 {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.body-text-black {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.body-text {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.Sub-h1 {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.Tabs-menu-selected {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.Tabs-menu {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #929292;
  text-transform: uppercase;
}
.Button-text {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
h3 {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
}
.Input-text {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
}

.Tiny-text-pink {
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff007f;
  text-transform: uppercase;
}
.Tiny-text {
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

ul {
  list-style: none; /* Remove default bullets */
}

ul li {
  display: flex;
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #ff007f; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  padding-right: 0.8rem;
  vertical-align: middle;
}

.numbering {
  counter-reset: item;
  list-style: none;
  margin: 0;
  padding-left: 1.2em !important;
}
.numbering li {
  counter-increment: item;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;

}
.numbering li::before {
  position: absolute;
  left: -30px;
  content: counter(item);
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 30px;
  text-align: right;
}

.numbering li:not:first-child {
  margin-top: 8px !important;
}

.h2-light {
  font-weight: 300;
}

.fixTopCon {
  background-color: #000000;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 10;
  height: 6.6rem;
  padding: 2.2rem 2.4rem;
}

.closeIcon {
  font-size: 2rem;
}

.iconBox {
  width: 1.2rem;
  height: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 2.7rem;
  right: 2.4rem;
  z-index: 15;
}

.react-swipeable-view-container > div[aria-hidden="false"] {
  height: 100%;
}

.react-swipeable-view-container > div[aria-hidden="true"] {
  height: 100%;
}

.hidden {
  display: none;
}

div[tabindex~="-1"] {
  outline: 0;
}

.slick-arrow {
  display: none !important;
}

#name-screen {
  color: rgb(192, 192, 192);
}

#name-screen fieldset {
  border: 1px solid rgb(192, 192, 192) !important;
}

#name-screen input {
  color: rgb(192, 192, 192);
}
#name-screen label {
  color: #464646;
}

.react-toast-notifications__container {
  z-index: 100000 !important;
}

.react-toast-notifications__toast--error {
  background: #fff !important;
  color: #ff007f !important;
}

.hide-bg .MuiBackdrop-root{
  background: transparent !important;
}

.react-toast-notifications__toast__content {
  color: #ff007f;
}
#screen-name-wrap {
  z-index: 2000 !important;
}

#screen-name-wrap input {
  color: #000 !important;
}

#screen-name-wrap .MuiDialog-paperWidthSm {
  width: 100%;
}

#screen-name-wrap.MuiDialog-root .MuiDialogActions-root {
  margin-top: 0;
}

#screen-name-wrap .MuiButton-contained.Mui-disabled{
  background-color: #AAAAAA !important;
  color: #6D6D6D;
}

#screen-name-wrap .MuiFormControl-root.MuiFormControl-marginNormal {
  margin-top: 0px;
}
#screen-name-wrap .MuiFormControl-root .MuiFormHelperText-root.Mui-error {
  padding-bottom: 2rem;
  display: block;
}
#screen-name-wrap .MuiFormControl-root .MuiFormHelperText-root {
  display: none;
}

.react-toast-notifications__toast__icon-wrapper{
  display: none;
}

.overlay-bg {
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0,0,0, 0.8);
}

#popup-wrapper.MuiDialog-root .MuiBackdrop-root ,
#screen-name-wrap.MuiDialog-root .MuiBackdrop-root {
  background-color: transparent;
}

#user-agent.MuiDialog-root a {
  color: #000;
}

@media all and (min-width: 960px) {
  .header-link-wrapper {
    padding: 10px;
    width: 100%;
    margin: auto;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#474747+0,000000+47 */
    background: rgb(71,71,71); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(71,71,71,1) 0%, rgba(0,0,0,1) 57%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(71,71,71,1) 0%,rgba(0,0,0,1) 57%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(71,71,71,1) 0%,rgba(0,0,0,1) 57%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#474747', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

  }
}

div[class^="BehindScene-itemContainer-"] .vjs-big-play-button {
  font-size: 6em !important;
  line-height: 1.5em !important;
  top: calc(50% - 3rem) !important;
  left: calc(50% - 3rem) !important;
  border: none !important;
  background: none !important;
  width: unset !important;
}
.overview-section-data p {
  text-transform: capitalize;
}

.intercom-launcher-frame {
   /* bottom: 58px !important; */
  right: 25px !important;
}

img:not(.beta-icon) {
  object-fit: cover;
}

.MuiDialog-root .MuiDialog-paper{
  margin: 20px;
}

.MuiDialog-root .MuiDialogActions-root{
  padding: 0;
  margin-top: 1.5em;
}

.MuiDialog-root .MuiBackdrop-root {
  background-color: rgba(0,0,0, 0.8);
}
.Mui-focusVisible:focus .MuiTouchRipple-root,
.Mui-focusVisible:focus .MuiTouchRipple-root,
.Mui-focusVisible:focus .MuiTouchRipple-root,
.Mui-focusVisible:focus .MuiTouchRipple-root{
  display: none;
}

.Mui-focusVisible:active .MuiTouchRipple-root,
.Mui-focusVisible:active .MuiTouchRipple-root,
.Mui-focusVisible:active .MuiTouchRipple-root {
  display: block;
}

.yc-admin-only-color, .yc-admin-only-color * {
  color: #bfff0f !important;
}

@media all and (min-width: 640px) {
  .video-js {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}


@media all and (min-width: 768px) {
  .react-orientation--portrait, .main-wrapper-yc {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
  }
}

.no-scroll-item .react-swipeable-view-container * {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.no-scroll-item .react-swipeable-view-container *::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.no-scroll-item .react-swipeable-view-container *::-webkit-scrollbar-thumb {
  background: transparent;
}

.no-scroll-item {
  height: 100%;
  overflow: hidden;
}

.ant-btn {
  font-weight: 600;
}

.inactive-modal .ant-modal-content {
  overflow: hidden;
}

.ant-modal-content {
  padding: 30px !important;
}