#bottom-bar-nav .ant-tabs-nav-list {
  justify-content: space-evenly;
  width: 100%;
}

#bottom-bar-nav .ant-tabs-tab {
  flex: 1;
  margin: 0;
  justify-content: center;
}

#bottom-bar-nav .ant-tabs-tab.ant-tabs-tab-active .anticon {
  color: #ff007f;
}