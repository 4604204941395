.link-to-button {
  font: bold 11px Arial;
  text-decoration: none;
  background-color: #EEEEEE;
  color: #333333;
  padding: 2px 6px 2px 6px;
  border-top: 1px solid #CCCCCC;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #CCCCCC;
  }

  .MuiButton-iconSizeMedium > *:first-child {
  font-size: 3rem !important;
}

.pdfView{
  margin: 1.5rem 2.3rem 2.3rem 2.3rem !important;
}

/****************************************************************************
* Show and hide of Display Group
****/
.hide-Slider{
  display: none !important;
}

.visually-Slider {
  opacity: 0;
  transition: all 3s ease-in-out;
}

.container1 {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  .container2 {
    max-width: 50%;
    flex: 1;
  }
  /********* Display Group **************/
  .Display-Container-Slider{
    width: 100%;
    height: 80px;
    margin-top: 1rem;
    transition: all 0.25s ease;
    cursor: pointer;
  }
  #hero-video {
    position: relative;
    max-height: 100vh;
    @media (orientation: landscape) {
      padding-top: 0.25%;
    }
  }
  .lessonContentCon {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    .swipeableViews {
      overflow: hidden;
      flex: 1;
      .react-swipeable-view-container {
        height: 100%;
      }
    }
    .subTabsCon {
      margin: 2.3rem 2.3rem;
      &.subTabsCon-steps {
        margin: 0;
        height: 100%;
        .steps-card {
          height: 100%;
          max-height: 500px;
          .steps-card-item {
            padding-top: 2.3rem;
            padding-bottom: 2.3rem;
            .steps-card-item-inner {
              border-radius: 8px;
              overflow: hidden;
              height: 100%;
              display: flex;
              flex-direction: column;
            }
            .MuiCardContent-root {
              background-color: rgb(30, 30, 30);
            }
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
      h4 {
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0;
        margin-top: 1.8rem;
      }
      ul {
        color: #ff007f;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 0;
      }
    }
  }


  @media all and (max-width: 960px) and (orientation: portrait){
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    .container2 {
      flex: none;
      max-width: 80vh;
      width: 100%;
    }
    .lessonContentCon {
      overflow: hidden;
      max-width: 960px;
      width: 100%;
    }

  }
  @media all and (max-width: 850px) and (orientation: landscape){
    flex-direction: column;
    align-items: center;
    .container2 {
      flex: none;
      max-width: 177vh;
      width: 100%;
    }
    .lessonContentCon {
      max-width: 960px;
      width: 100%;
    }
  }
  @media all and (max-width: 640px) {
    width: 100%;
    .container2 {
      width: 100%;
      max-width: none;
    }
  }
}

$transition-duration-in: 250ms;
$transition-duration-out: 1000ms;

.yc-modal {
    background-color: #1e1e1e;
    .MuiToolbar-regular {
        min-height: 24px;
        flex-direction: row-reverse;
    }
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperFullScreen {
        margin: 0;
    }
    .yc-modal-header {
        position: relative;
        background-color: transparent;
    }
}

.video-js .vjs-control *,
.video-js .vjs-progress-control {
  outline-style: none !important;
}
.video-js .vjs-control-bar .vjs-play-control {
  outline: -webkit-focus-ring-color none !important;
  outline-color: -webkit-focus-ring-color ;
  outline-style: none !important;
  outline-width: 0px !important;
}

.video-js .vjs-slider:focus {
  box-shadow: none !important;
}


.video-js .vjs-skip-next .vjs-icon-placeholder:before {
  font-family: 'Material Icons Round';
  content: "skip_next";
  font-size: 1.8em;
  line-height: 1.67;
  cursor: pointer;
}

.video-js .vjs-skip-previous .vjs-icon-placeholder:before {
  font-family: 'Material Icons Round';
  content: "skip_previous";
  font-size: 1.8em;
  line-height: 1.67;
  cursor: pointer;
}

.video-js .vjs-picture-in-picture-control {
  display: none;
}

.video-js .vjs-chapters-button .vjs-menu-item {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.video-js .vjs-texttrack-settings {
  display: none;
}

.video-js .vjs-time-control {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 1em;
}
.video-js .vjs-custom-endscreen-overlay {
  height: 90% !important;
}

.video-js .vjs-text-track-display {
  bottom: 4em;
}

@media all and (max-width: 428px) and (orientation: portrait){ // iPhone 12 Pro Max
  .video-js .vjs-current-time .vjs-time-control .vjs-control {
    display: block !important;
  }
  .video-js .vjs-control-bar .vjs-chapters-button {
    display: none;
  }
  .video-js .vjs-control:not(.vjs-progress-control) {
    width: 3em;
  }
  .video-js .vjs-volume-panel.vjs-volume-panel-vertical {
    display: none;
  }
  .video-js .vjs-custom-endscreen-overlay {
    height: 75% !important;
  }
  .video-js .vjs-current-time.vjs-time-control {
    margin-left: 0em;
  }
  .video-js .vjs-current-time.vjs-time-control,
  .video-js .vjs-duration.vjs-time-control,
  .video-js .vjs-time-divider.vjs-time-control {
    display: block !important;
  }
}