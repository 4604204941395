html {
    height: 100%;
    body {
        //background-color: black;
        height: 100%;
        .yc-app {
            display: flex;
            flex-direction: column;
            height: 100%;
            .yc-app-main {
                flex: 1;
                display: flex;
                overflow: hidden;
            }
        }
        .yc-header {
            flex: none;
            width: 100%;
        }
        .yc-body {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: auto;
            .yc-body-block {
                max-width: 768px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        .yc-main {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: auto;
            &.yc-main-chefpage {
                .swipe {
                    height: 100%;
                    .react-swipeable-view-container {
                        height: 100%;
                    }
                }
            } 
            &.yc-main-center {
                .yc-body {
                    .yc-body-center {
                        max-width: 768px;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin: auto;
                    }
                }
            }
        }
        #root {
            height: 100%;
            & > div {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }
    }
}
.MuiTabs-flexContainer .MuiTab-root {
    padding-top: 2px;
}

.formFrame {
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }

    .tf-v1-widget {
        height: 100%;
    }
}

.renewalModal {
    .ant-modal-content {
        background-color: #ffffff;
        border-radius: 6px;
    }

    .renewalTitle h2 {
        color: black;
        font-weight: 700;
        font-family: Open Sans;
        font-size: 18px;
        text-transform: none;
    }

    .renewalContent{
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        text-align: center;
        color: black;
    }

    .ant-btn{
        height: 44px;
        text-transform: uppercase;
    }
    
}
