.class-tabs .ant-tabs-nav-list {
  justify-content: space-evenly;
  width: 100%;
}

.class-tabs .ant-tabs-tab {
  justify-content: center;
}

.class-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}

@media (min-width: 600px) {
  .class-tabs .ant-tabs-tab {
    min-width: 160px;
  }
}